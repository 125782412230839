<template>
  <div class="form">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-col>
      <v-col cols="12" md="9" lg="6" class="formContent">
        <v-card-title class="formTitle">{{ form_title }}</v-card-title>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-alert
            v-model="ui.alert.enable"
            :dismissible="ui.alert.dismissible"
            :icon="ui.alert.icon"
            :type="ui.alert.type"
            class="mb-6 mt-1 "
            dense
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>

      <v-form ref="form" v-model="valid" v-if="!email_forgotten_password_sent" v-on:submit.prevent="chooseAction">
        <v-col cols="12" md="9" lg="6" class="formContent">
          <v-text-field v-model="formData.login" :label="this.$t('login.form.data.email')" :rules="[rules.required]"></v-text-field>
        </v-col>

        <!--   INPUT SHOW NLY IF LOGIN EXIST    -->
        <v-col v-if="user_exists && !loading && !forgotten_password &&!email_forgotten_password_sent" cols="12" md="9" lg="6"
               class="formContent">
          <v-text-field v-model="formData.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[rules.required]" @click:append="showPassword = !showPassword" :label="this.$t('login.form.data.password')"
                        required></v-text-field>

          <!--   LINK WHEN FORGOT PASSWORD   -->
          <a class="forgotten"
             @click="forgotten_password = true">{{ $t('forgotten_password.title') }}</a>
        </v-col>

        <v-col cols="12" md="9" lg="6" class="formContent">
          <v-btn v-if="!new_account && !email_forgotten_password_sent" class="connectBtn" @click="chooseAction">
            {{ btnLabel }}
          </v-btn>

          <v-card-text class="newAccount" v-if="!email_forgotten_password_sent">
            {{ $t('sign_up.incite_message.question') }} <a class="account" @click="$router.push({name: 'CreateAccount'})">{{$t('sign_up.incite_message.action')}}</a>
          </v-card-text>
        </v-col>

      </v-form>

      <!-- Show text that email reset password was sent     -->
      <v-col cols="12" md="9" lg="6" class="formContent">
        <v-card-text class="newAccount" v-if="email_forgotten_password_sent">
          {{ $t('forgotten_password.success_message') }}
        </v-card-text>
      </v-col>
    </v-col>
  </div>
</template>

<script>


import _ from "@/tools/lodash";

export default {
  name: "LoginForm",
  components: {},

  data() {
    return {
      loading: false,
      new_account: false, // whether or not user is creating a new account
      user_exists: false, // whether or not user login is in our recordds
      formData: {
        login: '',
        email: '',
        password: ''
      },
      valid: true, // whether or not form is valid
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        }
      },
      rules: {
        required: value => !!value || 'Required.',
      },
      form_title: '',
      form_title_options: {
        'user_not_exist': this.$t('login.form.title.user_not_exist'),
        'user_exists': this.$t('login.form.title.user_exist')
      },
      btnLabel: '',
      btnLabelOpt: {
        'connect': this.$t('login.form.button.connect'),
        'forgottenPassword': this.$t('forgotten_password.button.reset')

      },
      forgotten_password: false,
      email_forgotten_password_sent: false,
      showPassword: false,
    }
  },
  watch: {
    /**
     * Change button text and clear alerts on forgotten password
     * */
    forgotten_password: function () {
      if (this.forgotten_password) {
        this.btnLabel = this.btnLabelOpt.forgottenPassword
        this.ui.alert.enable = false
      }
    },
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    /**
     * Login or Create Account form
     *
     * If user exists, show password input & forgotten password option
     *
     * If user does not exist, redirect to create account
     *
     * */
    async chooseAction() {
      if (!this.user_exists) {
        await this.userExist()
      } else if (!this.forgotten_password)
        await this.connect()

      if (this.forgotten_password)
        await this.forgottenPassword()

    },
    /**
     * Check if user login
     * If yes => ask for password
     * If not => redirect to create account
     *
     * @returns {Promise<void>}
     */
    async userExist() {
      if (this.$refs.form.validate()) {

        this.loading = true
        try {
          const response = (await this.axios.post('/user/exist', {
            login: this.formData.login
          })).data

          this.user_exists = true

          // build title if user exists with user firstname and lastname
          const user_name = (!_.isEmpty(response.user_info.nickname) ? response.user_info.nickname : '')
          this.form_title = this.form_title_options.user_exists + (!_.isEmpty(user_name) ? (' ' + user_name + ',') : ',')

        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.user_exist.error.invalid_params'), true)
                break

                // User does not exist, redirect to create account
                // Set cookie with chosen user login and prefill create account
              case 404:
                this.user_exists = false
                this.new_account = true
                await this.$router.push({name: 'CreateAccount'}).then(() => {
                  // emit event to CreateAccount with chosen user login and prefill field
                  this.$root.$emit('user-login', this.formData.login)
                })
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.user_exist.error.invalid_method'), true)
                break

                // Organisation doesn't exist
              case 406:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.user_exist.error.orga_not_exist'), true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.user_exist.error.unknown'), true)
                break
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    /**
     * If user login exist
     * & given password ok connect
     * else wrong information
     * @returns {Promise<void>}
     */
    async connect() {
      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          const response = (await this.axios.post('/user/login', {
            login: this.formData.login,
            password: this.formData.password
          })).data

          /*Check if it's the first time to connect*/
          if (response.status === 'must_change_password') {
            this.$cookies.set('tmp_token', response.temp_auth_token)

            /* CHANGE ROUTE TO THE CHANGE PASSWORD AT FIRST LOG AND CONNECT */
            await this.$router.push({name: 'ChangePasswordFirstLog'})
          } else {
            /*Not the first time to connect, Get user infos from API and redirect*/
            this.$session.start()
            this.$session.set('token', !_.isUndefined(response.auth_token) ? response.auth_token : null)
            this.$session.set('key', !_.isUndefined(response.user.key) ? response.user.key : null)
            this.$session.set('name', !_.isUndefined(response.user.nickname) ? response.user.nickname : null)

            await this.$router.push({name: 'Home'})
          }

        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.sign_in.error.invalid_params'), true)
                break

                // Invalid login || password
              case 401:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.sign_in.error.invalid_login'), true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.sign_in.error.invalid_method'), true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('login.sign_in.error.unknown'), true)
                break
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    /**
     * check login and email data
     * to send a URL
     * for resetting password
     * @returns {Promise<void>}
     */
    async forgottenPassword() {

      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          await this.axios.post('/forgotten/password', {
            base_url: process.env.VUE_APP_WEBSITE,
            login: this.formData.login,
          })

          this.ui.alert.enable = false
          this.email_forgotten_password_sent = true

        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {
                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('forgotten_password.error.invalid_params'), true)
                break

                // User or orga not found
              case 401:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('forgotten_password.error.user_not_found'), true)
                break

                // User account suspended
              case 403:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('forgotten_password.error.account_suspended'), true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('forgotten_password.error.invalid_method'), true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', this.$t('forgotten_password.error.unknown'), true)
                break
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    /**
     * Keyboard enter action
     */
    keyboard_enter_action: function (e) {
      if (e.keyCode === 13) {
        this.chooseAction()
      }
    },
    /**
     * Listen keyboard enter request
     */
    listen_keyboard_enter_request: function () {
      document.addEventListener("keydown", this.keyboard_enter_action, false);
    },
    /**
     * Stop listen keyboard enter request
     */
    stop_listen_keyboard_enter_request: function () {
      document.removeEventListener("keydown", this.keyboard_enter_action, false);
    }
  },
  async mounted() {
    this.form_title = this.form_title_options.user_not_exist

    this.btnLabel = this.btnLabelOpt.connect

    this.listen_keyboard_enter_request();

    this.$root.$on('account-created', (data) => {
      this.user_exists = true
      this.formData.login = data.user_login
      this.form_title = this.form_title_options.user_exists + (!_.isEmpty(data.user_name_string) ? (' ' + data.user_name_string + ',') : ',')
    })


    /*SI UNE SESSION A DEJA ETAIT OUVERTE CE CONNECT2 AUTOMATIQUEMENT*/
    if (this.$session.has('token')) {
      /*await this.$router.push('/home')*/
    }

  },
  beforeDestroy() {
    this.$root.$off('account-created')
    this.stop_listen_keyboard_enter_request();
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-overlay__scrim {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
  }
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .formContent {
    margin: 0 auto;

    .formTitle {
      padding: 0;
      color: dimgray;
      font-size: 2rem;
      font-weight: bold;
    }

    .forgotten {
      font-size: 80%;
      display: flex;
      justify-content: right;
      color: RGBA(121, 7, 72, 1);
    }

    .v-progress-circular {
      margin: 1rem;
      color: RGBA(121, 7, 72, 1);
    }

    .connectBtn {
      width: 100%;
      padding: 0;
      color: #ffffff;
      font-size: 0.7rem;
      background-color: RGBA(121, 7, 72, 1);
    }

    .newAccount {
      padding: 2% 0 0 0;

      .account {
        color: RGBA(121, 7, 72, 1);

      }
    }

    .account {
      color: RGBA(121, 7, 72, 1);

    }
  }

}
</style>
